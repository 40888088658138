import React from 'react'
import {HashLink as Link} from 'react-router-hash-link';

const TopNav = ({lastName, firstName, title, menu, bookingUrl, subtitle}) => {
    return (
        <div>
            <div id='menu' className="text-center white-gradient-bg" style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                // backgroundColor: 'white',
                zIndex: 9999,
                paddingTop: '16px',
                paddingBottom: '32px',
            }}>
                <h2>{`${firstName} ${lastName}, ${title}`}</h2>
                {subtitle && <span className="site-description">{subtitle}</span>}
            </div>

            <div className='row justify-content-center top-nav-menu'>
                <div className='col-12 col-md-10 col-lg-6'>
                    <div className='row justify-content-around mt-2 mb-4'>
                        {
                            menu.map((menuItem) => {
                                return (
                                    <div key={menuItem.title} className='col text-center mt-2 mt-md-0'>
                                        <Link to={menuItem.url}>
                                            <u>{menuItem.title}</u>
                                        </Link>
                                    </div>
                                )
                            })
                        }

                        <div className='col text-center mt-2 mt-md-0'>
                            <a target="_blank" rel="noopener noreferrer"
                               href={bookingUrl}
                            >
                                <u>Booking</u>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TopNav