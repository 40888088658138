import React from 'react'
import ReactDOM from 'react-dom'
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom'
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

import V1 from '../components/v1/IndexView'
import S1 from '../components/s2/IndexView'
import RecordingPageFrame from '../components/video/RecordingPageFrame'
// import AuthenticationView from '../components/admin/AuthenticationView'
// import Amplify from 'aws-amplify';
// import {Authenticator} from "aws-amplify-react/dist/Auth";
// import {SignUp, SignIn, Greetings, ForgotPassword} from "aws-amplify-react";

// Amplify.configure({
//     Auth: {
//
//         // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
//         // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
//
//         // REQUIRED - Amazon Cognito Region
//         region: 'us-east-1',
//
//         // OPTIONAL - Amazon Cognito Federated Identity Pool Region
//         // Required only if it's different from Amazon Cognito Region
//         // identityPoolRegion: 'us-east-1',
//
//         // OPTIONAL - Amazon Cognito User Pool ID
//         userPoolId: 'us-east-1_DzCXDkrNc',
//
//         // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
//         userPoolWebClientId: '22tf4dpgnfvg7kiuhm00prgqfa',
//
//         // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
//         mandatorySignIn: true,
//
//         // OPTIONAL - Configuration for cookie storage
//         // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
//         // cookieStorage: {
//         //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
//         //     domain: '.yourdomain.com',
//         //     // OPTIONAL - Cookie path
//         //     path: '/',
//         //     // OPTIONAL - Cookie expiration in days
//         //     expires: 365,
//         //     // OPTIONAL - Cookie secure flag
//         //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
//         //     secure: true
//         // },
//
//         // OPTIONAL - customized storage object
//         // storage: new MyStorage(),
//
//         // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
//         // authenticationFlowType: 'USER_PASSWORD_AUTH'
//     }
// });

// You can get the current config object
// const currentConfig = Auth.configure();

const props = {
    serviceUrl: 'https://massagemeanmore.com/',
    // serviceUrl: 'http://localhost:3000',
    // serviceUrl: 'http://192.168.87.120:3000',
    bookingUrl: 'https://sonyascheer.janeapp.com',
    lastName: 'Simpson',
    firstName: 'Erica',
    subtitle: 'Licensed Massage Therapist',
    title: 'LMT',
    address: 'Placeholder address',
    email: 'Placeholder email',
    phone: 'Placeholder phone',
    menu: [
        {name: 'home', title: 'Home', url: '/#menu'},
        {name: 'about', title: 'About', url: '/#about'},
        {name: 'contact', title: 'Contact', url: '/#contact'},
    ],
    color: {
        white: 'white',
        black: 'black',
        textGray: '#787878',
        lightGray: '#f9f9f9',
        darkGray: '#eeeeee'
    }
}

const theme = createMuiTheme({
    palette: {
        type: 'light',
    },
});

document.addEventListener('DOMContentLoaded', () => {
    ReactDOM.render(
        <MuiThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <Route exact path="/" render={() => {
                        return (<V1 {...props} image='https://images.unsplash.com/photo-1534570122623-99e8378a9aa7?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'/>)
                    }}/>
                    <Route exact path="/topics" render={() => {
                        return (
                            <RecordingPageFrame
                                key='topics'
                                {...props}
                                orderBy='topics'
                            />
                        )
                    }}/>
                    <Route exact path="/videos" render={() => {
                        return (
                            <RecordingPageFrame
                                key='all'
                                {...props}
                                orderBy='all'
                            />
                        )
                    }}/>
                </Switch>
            </Router>
        </MuiThemeProvider>,
        document.body.appendChild(document.createElement('div')),
    )
})

