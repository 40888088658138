import React from 'react'

export default class TagLineFullWidth extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="row align-items-center justify-content-center"
             style={{
                 height: this.props.style.height,
                 minHeight: this.props.style.minHeight,
                 maxHeight: this.props.style.maxHeight,
                 backgroundImage: `url('${this.props.image}')`,
                 backgroundSize: 'cover',
                 backgroundRepeat: 'no-repeat',
                 backgroundPosition: this.props.backgroundPosition ? this.props.backgroundPosition : 'center center',
                 marginLeft: '-15px',
                 marginRight: '-15px'
             }}
        >

            <div className="col-10 text-white">
                <h2 className="text-center">Placeholder: Massage means more.</h2>

                {/*<p className='text-center'> is passionate that Massage Therapy can help you live your life to the fullest.</p>*/}

                <div className="text-center">
                    <a
                        // target="_blank"
                       rel="noopener noreferrer"
                       className="btn mt-4"
                       style={{
                           backgroundColor: this.props.color.white,
                           color: this.props.color.black,
                           textDecoration: 'underline'
                       }}
                       href='/'>
                        Book An Appointment
                    </a>
                </div>
            </div>
        </div>
    )
  }
}
