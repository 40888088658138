import React from 'react'

const Intro = ({bookingUrl, buttonStyle, style}) => {
    return (
        <div className="row justify-content-center">
            <div className="col-12" style={{maxWidth: style.maxWidth}}>
                <div className="row justify-content-center">
                    <div className='col-12 d-md-none text-center'>
                        <h2>Meet Erica</h2>
                    </div>
                    <div className='col-5 col-md-6 col-lg-4 p-0 text-center'>
                        <img
                            src="https://ac-massage-simpson-erica.s3.us-east-2.amazonaws.com/erica-professional.jpg"
                            alt=""
                            style={{
                                width: '100%',
                                // height: '100%',
                                maxWidth: '269.5px',
                                maxHeight: '400px'
                            }}
                        />
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 text-center'>
                        <h2 className='d-none d-md-inline-block'>Meet Erica</h2>
                        <p className='mt-2 ml-2 ml-md-0 text-left'>
                            Focus on Trigger Point Therapy, Therapeutic and Deep Tissue Techniques. Treatment is catered
                            to client needs and wishes, with goals set based on decreasing pain and increasing ROM. With
                            special attention to client communication, body assessment, and anatomy and trigger point
                            knowledge, each treatment builds on the last to achieve clients' goals.
                        </p>

                        <a target="_blank"
                           rel="noopener noreferrer"
                           className={`btn mt-md-2 ${buttonStyle || 'btn-success '}`}
                           style={buttonStyle}
                           href={bookingUrl}>
                            <u>Book An Appointment</u>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Intro
