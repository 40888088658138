import React from 'react'
import {resourceHeight} from '../util'

export default class SellingPoints extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const sectionColWidth = 12
        const sections = [
            {url: 'https://media.istockphoto.com/id/1648685317/photo/relax-woman-and-spa-face-massage-for-a-woman-for-glowing-smooth-and-healthy-skincare.jpg?s=612x612&w=is&k=20&c=etZhOySaAUYbCSU46e4wmAj-nm0G5-QFr3-OU4wVNz0=', title: 'Trigger Point Therapy'},
            {url: 'https://media.istockphoto.com/id/1648685585/photo/spa-rock-and-candle-to-relax-in-a-room-with-atmosphere-mood-or-ambience-in-a-health-club.jpg?s=612x612&w=is&k=20&c=Cyb-jJ_9vDCHEfQTj-sYjpu6mbwvi49A4ca6TQfYrmE=', title: 'Professional'},
            {url: 'https://plus.unsplash.com/premium_photo-1683141104249-69c85073a352?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', title: 'Therapeutic and Deep Tissue Techniques'}]

        return (
            <div className="row justify-content-center align-items-center text-right" style={this.props.style}>
                {
                    sections.map((section) => {
                        return (
                            <div key={section.title} className="col-10 col-sm-4 mt-3 mt-lg-0 p-0 text-center">
                                <img
                                    src={section.url}
                                    style={{
                                        width: '100%',
                                        height: `${resourceHeight(3, sectionColWidth, window.innerWidth > 500 ? 0.625 : 1)}vw`,
                                        minHeight: '200px',
                                        maxHeight: '350px',
                                        border: `1px solid ${this.props.color.textGray}`,
                                    }}
                                />
                                {section.title}
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}
