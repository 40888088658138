import React from 'react'

const Content1 = (props) => {
    const {style} = props
    return (
        <div>
            <div className='d-none d-md-block'>
                <div className="row justify-content-end align-items-center bg-dark text-white" style={style}>
                    <div className="col-12 col-md-6">
                        <div style={{margin: 30}}>
                            <h2>Therapeutic Massage</h2>
                            <p className='mt-4'>
                                Massage therapy relaxes muscle tissue, which reduces painful contractions and spasms.
                                Massage can also reduce
                                nerve
                                compression.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mt-4 mt-md-0 p-0" style={{
                        backgroundImage: `url('https://images.unsplash.com/photo-1493815793585-d94ccbc86df8?q=80&w=1373&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        height: style.height,
                        minHeight: style.minHeight,
                        maxHeight: style.maxHeight,
                    }}>
                        {/*<img src="https://ac-hu-sonya.s3-us-west-1.amazonaws.com/flower_image_4.jpeg" alt=""/>*/}
                    </div>
                </div>
            </div>
            <div className='d-md-none'>
                <div className='row' style={{
                    // backgroundImage: `url('https://images.unsplash.com/photo-1493815793585-d94ccbc86df8?q=80&w=1373&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    height: style.height,
                    minHeight: style.minHeight,
                    maxHeight: style.maxHeight,
                    background: 'linear-gradient(rgba(0, 0, 0, .35), rgba(0, 0, 0, .35)), url("https://images.unsplash.com/photo-1493815793585-d94ccbc86df8?q=80&w=1373&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")'
                }}>
                    <div className='col-12 h-100'>
                        <div className='row align-items-center h-100'>
                            <div className='col-12 text-center text-white align-content-center'>
                                <div>
                                    <h2 style={{zIndex: 100}}>Therapeutic Massage</h2>
                                    <p className='mt-4' style={{zIndex: 10}}>
                                        Massage therapy relaxes muscle tissue, which reduces painful contractions and
                                        spasms. Massage can also
                                        reduce nerve
                                        compression.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content1