import React from 'react'
import {withRouter} from 'react-router-dom'
import TopNav from '../shared/TopNav'
import Footer from '../shared/Footer'
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ShowMore from 'react-show-more';
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Drawer from "@material-ui/core/Drawer";
import Modal from '@material-ui/core/Modal';
import RecordingUpdateView from "./RecordingUpdateView";
// import {Auth} from "aws-amplify";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TopicUpdateModal from "./TopicUpdateModal";
import Divider from "@material-ui/core/Divider";
import {HashLink as Link} from "react-router-hash-link";

class RecordingIndexView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: this.props.categories || [],
            recordings: this.props.recordings || [],
            drawerOpen: false,
            editRecording: null,
            modalOpen: false,
            menuOpen: false,
            orderBy: this.props.orderBy
        }

        this.closeDrawer = this.closeDrawer.bind(this)
        this.topNav = this.topNav.bind(this)
        this.createRecording = this.createRecording.bind(this)
        this.getRecordings = this.getRecordings.bind(this)
        this.getCategories = this.getCategories.bind(this)
        this.orderByAlphabetical = this.orderByAlphabetical.bind(this)
        this.orderByTopics = this.orderByTopics.bind(this)
        this.refresh = this.refresh.bind(this)
    }

    closeDrawer(refresh) {
        if (refresh) {
            this.getRecordings()
            this.setState({
                recordings: [],
                drawerOpen: false,
                editRecording: null
            })
        } else {
            this.setState({
                drawerOpen: false,
                editRecording: null
            })
        }
    }

    getCategories() {
        return fetch(`${this.props.serviceUrl}/categories`, {
            credentials: 'same-origin',
            // credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Token': this.props.authData ? this.props.authData.signInUserSession.accessToken.jwtToken : ''
            },
            method: 'get'
        }).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({categories: data})
        })
    }

    getRecordings() {
        return fetch(`${this.props.serviceUrl}/recordings`, {
            credentials: 'same-origin',
            // credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Token': this.props.authData ? this.props.authData.signInUserSession.accessToken.jwtToken : ''
            },
            method: 'get'
        }).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({recordings: data})
        })
    }

    createRecording() {
        return fetch(`${this.props.serviceUrl}/recordings`, {
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Token': this.props.authData ? this.props.authData.signInUserSession.accessToken.jwtToken : ''
            },
            method: 'post'
        }).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({
                editRecording: data,
                drawerOpen: true
            })
        })
    }

    componentDidMount() {
        if (this.state.recordings.length == 0 || this.state.categories.length == 0) {
            this.getRecordings()
            this.getCategories()
        }
    }

    refresh() {
        this.setState({
            recordings: [],
            categories: [],
            modalOpen: false
        })
        this.getRecordings()
        this.getCategories()
    }

    orderByAlphabetical() {
        let hash = {}
        this.state.recordings.forEach((rec) => {
            let firstLetter = rec.name[0].toUpperCase()
            hash[firstLetter] == null ? hash[firstLetter] = [rec] : hash[firstLetter].push(rec)
        })
        let list = Object.keys(hash).sort()
        let sortedList = list.map((index) => {
            return ({key: index, values: hash[index]})
        })
        return (sortedList)
    }

    orderByTopics() {
        let unTaggedRecordings = []
        let hash = {}
        this.state.recordings.forEach((rec) => {
            if (rec.categories.length == 0) {
                unTaggedRecordings.push(rec)
            } else {
                rec.categories.forEach((topic) => {
                    hash[topic.name] == null ? hash[topic.name] = [rec] : hash[topic.name].push(rec)
                })
            }
        })
        let list = Object.keys(hash).sort()
        let sortedList = list.map((index) => {
            return ({key: index, values: hash[index]})
        })
        if (unTaggedRecordings.length > 0) {
            sortedList.push({key: 'Others', values: unTaggedRecordings})
        }
        return (sortedList)
    }

    topNav() {
        const {authState, menu} = this.props
            return (
                <div className='row align-items-center pt-3'>
                    <div className='col text-left'>
                        <IconButton
                            color="primary"
                            aria-label="add new recording"
                            onClick={this.createRecording}
                        >
                            <AddIcon/>
                        </IconButton>
                    </div>
                    <div className='col text-center'>
                    <div className='row'>
                        {
                            authState == 'signedIn' ?
                                menu.map((menuItem) => {
                                    return (
                                        <div key={menuItem.title} className='col text-center mt-2 mt-md-0'>
                                            <Link to={menuItem.url}>
                                                <u>{menuItem.title}</u>
                                            </Link>
                                        </div>
                                    )
                                }) : ''
                        }
                    </div>
                    </div>
                    <div className='col text-right'>
                        <IconButton
                            color="primary"
                            aria-label="log out"
                            onClick={() => Auth.signOut()}
                        >
                            <ExitToAppIcon/>
                        </IconButton>
                    </div>
                </div>
            )
    }

    divider(sectionColWidth) {
        return (
            <div className='row justify-content-center'>
                <div className={`col-12 col-md-${sectionColWidth} pt-4 p-0 mb-2 mb-md-5`}>
                    <Divider variant="middle" className='ml-0 mr-0'/>
                </div>
            </div>
        )
    }

    render() {
        if (this.state.recordings.length == 0 || this.state.categories.length == 0) {
            return (
                <div className='container-fluid'>
                    <div className='row justify-content-center align-items-center' style={{height: '100vh'}}>
                        <CircularProgress/>
                    </div>
                </div>
            )
        }

        const {authState} = this.props
        const recordings = this.orderByTopics()
        return (
            <div className='container-fluid'>
                {  authState == 'signedIn' ? this.topNav() : ''}
                {  authState == 'signedIn' ? this.divider(12) : ''}

                <div className="row justify-content-center mb-5">
                    <div className="col-12 col-lg-10">
                    {
                        recordings.map((recording) => {
                            const key = recording.key
                            return (
                                <ExpansionPanel
                                    key={key}
                                    defaultExpanded={true}
                                    style={{
                                        marginLeft: '-15px',
                                        marginRight: '-15px',
                                        backgroundColor: this.props.color.lightGray
                                    }}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        // style={{borderBottom: '1px solid #343a40'}}
                                    >
                                        {
                                            key == 'Others' || this.state.orderBy == 'Alphabetical' || authState != 'signedIn' ?
                                                <div>
                                                    {/*<AddToQueueIcon className='mr-1'/>*/}
                                                    <strong>{`${key[0].toUpperCase()}${key.slice(1, key.length)}`}</strong>
                                                </div>
                                                :
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    color='primary'
                                                    // startIcon={<EditIcon/>}
                                                    onClick={(event) => {
                                                        event.stopPropagation()
                                                        this.setState({
                                                            modalOpen: true,
                                                            editCategory: key
                                                        })
                                                    }}>
                                                    {key}
                                                </Button>
                                        }
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails
                                        style={{
                                            padding: '0',
                                            paddingBottom: '16px',
                                            backgroundColor: this.props.color.lightGray
                                        }}
                                    >
                                        <div className='row w-100 m-0'>
                                            {
                                                recording.values.map((recording) => {
                                                    return (
                                                        <div key={key + recording.id}
                                                             className={`col-12 col-md-6 col-lg-4 col-xl-3 mt-5`}
                                                        >
                                                            <Card className='h-100'>
                                                                {
                                                                    authState == 'signedIn' ?
                                                                        <CardActions disableSpacing>
                                                                            <IconButton
                                                                                color="primary"
                                                                                aria-label="edit"
                                                                                size='small'
                                                                                style={{marginLeft: 'auto'}}
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        drawerOpen: true,
                                                                                        editRecording: recording
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <EditIcon/>
                                                                            </IconButton>
                                                                        </CardActions> : ''
                                                                }
                                                                {
                                                                    recording.url && recording.format == 'youtube' &&
                                                                    <div className='row align-content-center text-center'
                                                                         style={{border: '1px solid dark-gray'}}>
                                                                        <div className='col-12' style={{
                                                                            position: 'relative',
                                                                            paddingBottom: '56.25%',
                                                                            height: 0,
                                                                        }}>
                                                                            <iframe
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                    width: '100%',
                                                                                    height: '100%',
                                                                                }}
                                                                                src={recording.url}
                                                                                frameBorder="0"
                                                                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                                                allowFullScreen
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    recording.url && recording.format == 'file' &&
                                                                    <div className='row align-content-center text-center'
                                                                         style={{border: '1px solid dark-gray'}}>
                                                                        <div className='col-12' style={{
                                                                            position: 'relative',
                                                                            paddingBottom: '56.25%',
                                                                            height: 0,
                                                                        }}>
                                                                            <video preload="true" disablePictureInPicture controls controlsList="nodownload"
                                                                                   style={{
                                                                                       position: 'absolute',
                                                                                       top: 0,
                                                                                       left: 0,
                                                                                       width: '100%',
                                                                                       height: '100%',
                                                                                   }}>
                                                                                <source src={recording.url + '#t=0.1'} type="video/mp4"/>
                                                                            </video>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    !recording.url &&
                                                                    <div className='row align-content-center text-center'
                                                                         style={{height: '360px', border: '1px solid dark-gray'}}>
                                                                        <div className='col-12'>
                                                                            No Recording File ...
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <CardContent>
                                                                    <Typography gutterBottom>
                                                                        <strong>{recording.name}</strong>
                                                                    </Typography>
                                                                    <div className='small'>
                                                                        <ShowMore
                                                                            lines={3}
                                                                            more='Show more'
                                                                            less='Show less'
                                                                            anchorClass=''
                                                                        >
                                                                            {recording.description}
                                                                        </ShowMore>
                                                                    </div>
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )
                        })
                    }
                    </div>
                    <div>
                        {
                            authState == 'signedIn' ?
                                <div>
                                    <Drawer
                                        anchor="right"
                                        open={this.state.drawerOpen}
                                        onClose={this.closeDrawer}
                                    >
                                        <RecordingUpdateView
                                            key={this.state.editRecording || new Date()}
                                            {...this.props}
                                            recording={this.state.editRecording}
                                            categories={this.state.categories}
                                            close={this.closeDrawer}
                                        />
                                    </Drawer>
                                    <Modal
                                        aria-labelledby="simple-modal-title"
                                        aria-describedby="simple-modal-description"
                                        open={this.state.modalOpen}
                                        // onClose={() => {
                                        //     this.setState({modalOpen: false})
                                        // }}
                                        disableBackdropClick={true}
                                    >
                                        <TopicUpdateModal
                                            {...this.props}
                                            topic={this.state.editCategory}
                                            categories={this.state.categories}
                                            close={() => {
                                                this.setState({modalOpen: false})
                                            }}
                                            refresh={this.refresh}
                                        />
                                    </Modal>
                                </div> : ''
                        }
                    </div>
                    <div className='col-12 col-lg-10 text-right pt-5'>
                        <a href='#' onClick={()=> {window.scrollTo(0, 0)}}>
                            <u>Back To Top</u>
                        </a>
                    </div>
                </div>
                {
                    authState == 'signedIn' ?
                        <Footer {...this.props}
                                filter={['about', 'videos', 'contact', 'booking']}
                                style={{
                                    backgroundColor: '#343a40',
                                    marginTop: '15px'
                                }}
                        /> : ''
                }
            </div>
        )
    }
}

export default withRouter(RecordingIndexView)
